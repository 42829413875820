<template>
  <div class="detail">
	<van-divider>订单号: {{$route.query.logistics}}</van-divider>
    <van-steps direction="vertical" :active="0" v-if="list.length > 0">
      <van-step v-for="(v, k) in list">
        <h3>{{v.AcceptStation}}</h3>
        <p>{{v.AcceptTime}}</p>
      </van-step>
    </van-steps>
	<van-empty v-else description="暂无物流信息" />
  </div>
</template>

<script>
	export default {
		name: 'Detail',
		components: {
		},
		data() {
		  return {
			  list: []
		  };
		},
		mounted() {
			this.getWl()
		},
		methods: {
		 getWl() {
			let url = `${this.url}/index/order/emsInfo`
			this.axios.get(url, {
			　　params: {id: this.$route.query.id}
			}).then(response => {
				if (response.data.code == 200) {
					// that.list = response.data.data
					if (response.data.data.Success == true) {
						this.list = response.data.data.Traces.reverse()
						// console.log(this.list)
					}
				}
			}).catch(error => {
				console.log(error);
			}) 
		 },
		},
	}
</script>

<style scoped="scoped">
	.detail {
		text-align: left;
	}
</style>