<template>
	<div class="pickup">
		<van-row>
			<van-col span="24">
				<van-form @submit="onSubmit" :show-error="false">
				<van-field
					  readonly
					  clickable
					  name="category"
					  :value="form.category"
					  label="分类"
					  placeholder="点击选择分类"
					  @click="showPicker = true"
					  :rules="[{ required: true, message: '请选择分类' }]"
					/>
					<van-popup v-model="showPicker" position="bottom">
					  <van-picker
					    show-toolbar
					    :columns="columns"
					    @confirm="onConfirm10"
					    @cancel="showPicker = false"
					  />
					</van-popup>

				  <van-field
				    autocomplete="off"
					v-model="form.number"
					name="number"
					label="卡号"
					placeholder="卡号"
					:rules="[{ required: true, message: '请填写卡号' }]"
				  />
				  <van-field
					v-model="form.password"
					name="password"
					label="密码"
					placeholder="密码"
					:rules="[{ required: true, message: '请填写密码' }]"
				  />
				  <div style="margin-top: 10px;"></div>
				  <van-field
					autocomplete="off"
					v-model="form.name"
					name="name"
					label="收件人"
					placeholder="收件人姓名"
					:rules="[{ required: true, message: '请填写收件人' }]"
				  />
				  <van-field
					autocomplete="off"
					v-model="form.phone"
					name="phone"
					label="手机"
					placeholder="手机"
					:rules="[{ pattern, message: '手机号格式不正确' }]"
				  />
				  <van-field
				    readonly
				    clickable
				    name="address"
				    :value="form.address"
				    label="地址"
				    placeholder="地址"
				    @click="showArea = true"
					:rules="[{ required: true, message: '请填写地址' }]"
				  />
				  <van-popup v-model="showArea" position="bottom">
				    <van-area
				      :area-list="areaList"
				      @confirm="onConfirm"
				      @cancel="showArea = false"
				    />
				  </van-popup>
				  <van-field
					autocomplete="off"
					v-model="form.address_detail"
					name="address_detail"
					label="详细地址"
					placeholder="详细地址"
					:rules="[{ required: true, message: '请填写详细地址' }]"
				  />
				  <div style="margin-top: 10px;"></div>
				  <van-field
				    readonly
				    clickable
				    name="need_send_time"
				    :value="form.need_send_time"
				    label="发货时间"
				    placeholder="点击选择发货时间"
				    @click="showCalendar = true"
				  />
				  <van-calendar v-model="showCalendar" @confirm="onConfirm1" />	
				  <van-field
					autocomplete="off"
					v-model="form.remark"
					name="remark"
					label="备注"
					placeholder="备注"
				  />
				  
				  <div style="margin: 16px;">
				    <van-button round block type="info" native-type="submit">确认提货</van-button>
				  </div>
				</van-form>
			</van-col>
		</van-row>
	</div>
</template>

<script>
	import { areaList } from '@vant/area-data'
	import qs from 'qs'
	export default {
		name: 'Home',
		components: {
		},
		mounted() {
			// let date = new Date()
			// let month = date.getFullYear()
			// let month = date.getMonth() + 1
			// let date = date.getDate() + 1
			// this.mindate = 
			this.getSelect()
		},
		data() {
		  return {
			columns: [],
			showPicker: false,
			
			showCalendar: false,
			showArea: false,
			areaList: areaList,
			pattern: /^1[3456789]\d{9}$/,
			// mindate: ,
			form: {
				number: '',
				password: '',
				name: '',
				phone: '',
				address: '',
				address_detail: '',
				need_send_time: '',
				remark: '',

				category: ''

			}
		  };
		},
		methods: {
			getSelect() {
				let url = `${this.url}/index/order/getSelect`
				this.axios.get(url, {
				　　params: {}
				}).then(response => {
					if (response.data.code == 200) {
						this.columns = response.data.data;
					}
				}).catch(error => {
					console.log(error);
				}) 
			},
			onConfirm10(value) {
				this.form.category = value;
		      	this.showPicker = false;

		      	if (this.form.category) {
			      	// 验证分类是否有备注
					let url = `${this.url}/index/order/getCategoryStatus`
					this.axios.get(url, {
					　　params: {category: this.form.category}
					}).then(response => {
						if (response.data.code == 200 && response.data.data!= null) {
							this.$dialog.alert({
						      message: response.data.data.content,
						    });
						}
					}).catch(error => {
						console.log(error);
				}) 
		      	}
				
		      
		    },
		  onSubmit(values) {
			let url = `${this.url}/index/order/exchange`
			this.axios({
				headers: {
					'Content-Type':'application/x-www-form-urlencoded'
				},
				method: 'post',
				url: url,
				data: qs.stringify(values)
			}).then(response => {
				if (response.data.code == 200) {
					this.$router.push({path:'/finish',query: {}})
				} else {
					this.$toast(response.data.msg)
				}
				
			}).catch(error => {
				console.log(error);
			})
		  },
		  onConfirm(values) {
			this.form.address = values
			  .filter((item) => !!item)
			  .map((item) => item.name)
			  .join('/');
			this.showArea = false;
		  },
		  // 日历
		  onConfirm1(date) {
			let datetime = new Date()
			let y = datetime.getFullYear()
			let m = datetime.getMonth() + 1
			let d = datetime.getDate()
			
			let nd = `${y}-${m}-${d}`
			let sd = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
			if (nd == sd) {
				this.$toast('不能选择今日发货')
			} else {
				this.form.need_send_time = sd
				this.showCalendar = false
			}
		  },
		},
	}
</script>

<style>
.pickup {
	background: #eee;
	height: 100vh;
	width: 100vw;
}
.box {
	background-color: #42B983;
}
</style>
