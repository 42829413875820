<template>
  <div class="my">
	<div>
		<van-search
		  v-model="search"
		  show-action
		  label="手机号"
		  placeholder="请输入手机号"
		  @search="onSearch"
		>
		  <template #action>
		    <div @click="onSearch">搜索</div>
		  </template>
		</van-search>
		<div v-if="list.length > 0">
			<van-card v-for="(v, k) in list"
			  :title="format1(v.name, v.phone)"
			  :desc="format2(v.address, v.address_detail)"
			  currency=""
			  @click = "detail(v.id, v.logistics)"
			>
			  <template #tags>
				<div>备注: {{v.remark}}</div>
				<div>发货时间: 
				<span v-if="v.send_time">{{v.send_time}}</span>
				<span v-else>暂未发货</span>
				</div>
			  </template>
			  <template #footer>
				<!-- <van-tag plain type="danger">{{v.logistics}}</van-tag> -->
				<!-- <van-tag plain type="danger"></van-tag> -->
				<div v-if="v.number">卡号: {{v.number}}</div>
				<div v-if="v.logistics">订单号: {{v.logistics}}</div>
			  </template>
			</van-card>
			
		</div>
		<van-empty v-else description="无数据" />
	
	</div>
  </div>
</template>

<script>
	export default {
		name: 'My',
		components: {
		},
		data() {
		  return {
			search: '',
			list: [],
		  };
		},
		methods: {
		  format1(name, phone) {
			  return name + ' ' + phone
		  },
		  format2(address, address_detail) {
			  return address + ' ' + address_detail
		  },	
		  onSearch() {
			  let that = this
			  let url = `${this.url}/index/order/phoneCoupon`
			  this.axios.get(url, {
			  　　params: {phone: this.search}
			  }).then(response => {
			  	if (response.data.code == 200) {
			  		that.list = response.data.data
					// console.log(response.data.data)
			  	}
			  }).catch(error => {
			  	console.log(error);
			  }) 
		  },
		  detail(id, logistics) {
			  if (logistics) {  
				this.$router.push({path:'/detail',query: {id: id, logistics:logistics}})
			  } else {
				  this.$toast('暂未发货')
			  }
		  },
		},
	}
</script>

<style scoped="scoped">
.my {
	text-align: left;
}
</style>