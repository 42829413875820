<template>
  <div class="home">
	  <van-row>
	    <van-col span="24">
			<van-swipe :autoplay="3000">
			  <van-swipe-item v-for="(image, index) in images" :key="index">
			    <img width="100%" height="200" v-lazy="image.img" />
			  </van-swipe-item>
			</van-swipe>
		</van-col>
	  </van-row>
	  <div class="op">
	  	<div class="fz">
			<van-button type="warning" round size="normal" @click="th">我要提货</van-button>
		</div>
	  	<div class="fz">
			<van-button type="warning" round size="normal" to="/my">我的物流</van-button>
		</div>
	  </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
  },
  mounted() {
	  this.getSwipers()
  },
  data() {
	  return {
		images: [],
	  }
  },
  methods: {
  	th() {
  		this.tankuang()
  		
  	},
  	tankuang() {
  			let url = `${this.url}/index/order/tankuang`
				this.axios.get(url, {
				　　params: {}
				}).then(response => {
					if (response.data.code == 200 && response.data.data == null) {
						this.$router.push('/pickup')
					} else {
						this.$dialog.alert({
				      message: response.data.data.content,
				    });
					}
				}).catch(error => {
					console.log(error);
				}) 
    },
	  getSwipers() {
		// axios({
		// 	headers: {
		// 		'Content-Type': 'multipart/form-data'
		// 	},
		// 	method: 'post',
		// 	url: "{:url('/phone/upload')}",
		// 	data: formData
		// }).then(response => {
		// 	this.$toast(response.data.msg)
		// }).catch(error => {
		// 	console.log(error);
		// })
		let url = `${this.url}/index/index/banner`
		this.axios.get(url, {
		　　params: {}
		}).then(response => {
			if (response.data.code == 200) {
				this.images = response.data.data
			}
		}).catch(error => {
			console.log(error);
		}) 
	  },
  },
}
</script>

<style scoped="scoped">
.home {
	width: 100%;
	height: 100%;
	background: url(../../public/bg1.png);
	background-size: 100% 100%; 
}
/deep/ .van-button--normal {
	font-size: 20px;
	font-weight: 700;
	padding: 20px;
}
.fz {
	margin-top: 10vh;
}
</style>